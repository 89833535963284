import { ColDef, ColGroupDef, RowNode, SortChangedEvent } from 'ag-grid-community';
import { IAPISortFilter } from '../Interfaces';
import {
  IAPISearchFilter,
  Utilities,
  SORTING_DIRECTION,
  IAPIFilterDictionary,
  IGridSortFilter,
} from '@wings-shared/core';
import { IGridState } from '../Components';

export function useGridFilters<T>(apiFilterDictionary: IAPIFilterDictionary<T>[], gridState: IGridState) {
  // ag grid life cycle method
  const onSortChanged = ({ api, columnApi }: SortChangedEvent): void => {
    if (!gridState.gridApi) {
      return;
    }
    // redraw rows to get the updated row index
    gridState.gridApi.redrawRows();
    gridState.setInitialColDefs(api.getColumnDefs());
    gridState.setSortFilters(api.getSortModel());
  };

  // Private Ignore sorting for new Row
  const postSort = (rowNodes: RowNode[]): void => {
    const rowIndex = rowNodes.findIndex(({ data }) => !Boolean(data.id));
    if (rowIndex !== -1) {
      const addIndex: number = gridState.gridApi?.getFirstDisplayedRow();
      rowNodes.splice(addIndex, 0, rowNodes.splice(rowIndex, 1)[0]);
    }
  };

  // ag grid life cycle method
  const onFilterChanged = (): void => {
    // redraw rows to get the updated rowIndex
    if (!gridState.gridApi) {
      return;
    }

    gridState.gridApi.redrawRows();
    if (!gridState.gridApi.getDisplayedRowCount()) {
      gridState.gridApi.showNoRowsOverlay();
      return;
    }
    gridState.gridApi.hideOverlay();
  };

  // Filters If Filters are applied on Grid Column
  const gridColumnFilters = (useAsSearchCollection = false) => {
    if (!gridState.isColumnFilterApplied) {
      return null;
    }

    const gridAPISearchFilters: IAPISearchFilter[] = Array.from(gridState.columFilters.keys()).reduce<
      IAPISearchFilter[]
    >((acc: IAPISearchFilter[], coldId: string) => {
      const filterAPI = gridState.gridApi.getFilterApiForColDef(gridState.gridApi.getColumnDef(coldId));
      let selectedValues: string[] = [];

      if (Boolean(filterAPI?.valueModel)) {
        selectedValues = Array.from(filterAPI?.valueModel?.selectedValues);
      }

      const property = apiFilterDictionary.find(({ columnId }) => Utilities.isEqual(columnId, coldId));
      if (property && selectedValues.length > 0) {
        const hasMultiValue: boolean = selectedValues.length > 1;
        acc.push({
          propertyName: property.apiPropertyName,
          operator: 'and',
          propertyValue: hasMultiValue ? selectedValues : selectedValues[0],
          filterType: hasMultiValue ? 'in' : 'eq',
        });
      }
      return acc;
    }, []);
    return gridAPISearchFilters.length ? { filterCollection: JSON.stringify(gridAPISearchFilters) } : null;
  };

  // Filters If Sorting Is Applied on Grid
  const gridSortFilters = () => {
    if (!Array.isArray(gridState.sortFilters)) {
      return null;
    }
    const apiSortFilters: IAPISortFilter[] = gridState.sortFilters.reduce<IAPISortFilter[]>(
      (acc: IAPISortFilter[], currentValue: IGridSortFilter) => {
        const property = apiFilterDictionary.find(({ columnId }) => Utilities.isEqual(columnId, currentValue.colId));
        if (property) {
          acc.push({
            propertyName: property.apiPropertyName,
            isAscending: currentValue.sort === SORTING_DIRECTION.ASCENDING,
          });
        }
        return acc;
      },
      []
    );
    return apiSortFilters.length ? { sortCollection: JSON.stringify(apiSortFilters) } : null;
  };

  // suppress filters and sortable in row editing mode to prevent user interaction
  const suppressFilters = (suppress: boolean): void => {
    if (!suppress) {
      gridState.gridApi.setColumnDefs(gridState.initialColDefs);
      return;
    }
    const colDefs: (ColDef | ColGroupDef)[] = gridState.gridApi.getColumnDefs().map((column: ColDef | ColGroupDef) => {
      column = setColDefProp(column, 'suppressMenu', true);
      column = setColDefProp(column, 'sortable', false);
      // needs for group columns
      if ('children' in column && column.children.length) {
        column.children = column.children.map((childColumn: ColDef) => {
          childColumn = setColDefProp(childColumn, 'suppressMenu', true);
          childColumn = setColDefProp(childColumn, 'sortable', false);
          return childColumn;
        });
      }
      return column;
    });
    gridState.gridApi.setColumnDefs(colDefs);
  };

  const setColDefProp = (colDef: ColDef, key: keyof ColDef, value: boolean): ColDef | ColGroupDef => {
    return { ...colDef, [key]: value };
  };

  // Remove All Column Filters from ag grid header
  const resetColumnFilters = (): void => {
    Array.from(gridState.columFilters.keys()).forEach((key: string) => {
      // Set Model Clear the applied filter model
      gridState.gridApi.getFilterApiForColDef(key)?.setModel(null);
    });
    gridState.resetColumnFilter();
    gridState.gridApi.onFilterChanged();
  };

  return {
    apiFilterDictionary,
    gridSortFilters,
    gridColumnFilters,
    resetColumnFilters,
    suppressFilters,
    onFilterChanged,
    postSort,
    onSortChanged,
  };
}
