import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { ColDef, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { VIEW_MODE, NO_SQL_COLLECTIONS } from '@wings/shared';
import { useAgGrid, CustomAgGridReact, useGridState, agGridUtilities } from '@wings-shared/custom-ag-grid';
import { observer, inject } from 'mobx-react';
import { useUnsubscribe } from '@wings-shared/hooks';
import { finalize, takeUntil } from 'rxjs/operators';
import { GridPagination, IAPIGridRequest, UIStore, GRID_ACTIONS, Utilities, SearchStore } from '@wings-shared/core';
import { BulletinModel } from './Models';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import { CustomLinkButton } from '@wings-shared/layout';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { BulletinStore } from './Stores/Bulletin.store';
import { BULLETIN_FILTERS } from './Enums';
import { gridFilters } from './fields';
import { useLocation } from 'react-router-dom';

interface Props {
  bulletinStore?: BulletinStore;
  securityModule: any;
  collectionName: NO_SQL_COLLECTIONS;
  filters?: any;
}

const Bulletins: FC<Props> = observer(({ bulletinStore, securityModule, collectionName }) => {
  const unsubscribe = useUnsubscribe();
  const gridState = useGridState();
  const location = useLocation();
  const agGrid = useAgGrid<BULLETIN_FILTERS, BulletinModel>(gridFilters, gridState);
  const searchHeaderRef = useRef<ISearchHeaderRef>();

  // Load Data on Mount
  useEffect(() => {
    // Restore Search Result based on available history
    const searchData = SearchStore.searchData.get(location.pathname);
    if (searchData) {
      gridState.setPagination(searchData.pagination);
      searchHeaderRef.current?.setupDefaultFilters(searchData);
      SearchStore.clearSearchData(location.pathname);
      return;
    }
    loadInitialData();
  }, []);

  const getFilterCollection = (): IAPIGridRequest => {
    if (!searchHeaderRef.current.searchValue) {
      return {};
    }
    const property = gridFilters.find(({ uiFilterType }) =>
      Utilities.isEqual(uiFilterType as string, searchHeaderRef.current.selectedOption)
    );
    return {
      searchCollection: JSON.stringify([
        { propertyName: property.apiPropertyName, propertyValue: searchHeaderRef.current.searchValue },
      ]),
    };
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...getFilterCollection(),
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
    };
    UIStore.setPageLoader(true);
    bulletinStore
      ?.getBulletinsNoSql(request, collectionName)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(response => {
        gridState.setPagination(new GridPagination({ ...response }));
        gridState.setGridData(response.results);
      });
  };

  /* istanbul ignore next */
  const columnDefs: ColDef[] = [
    {
      headerName: 'Bulletin Level',
      field: 'bulletinLevel',
      headerTooltip: 'Bulletin Level',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Bulletin Entity',
      field: 'bulletinEntity',
      headerTooltip: 'Bulletin Entity',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Bulletin Source',
      field: 'bulletinSource',
      headerTooltip: 'Bulletin Source',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'UAOffice',
      field: 'uaOffice',
      headerTooltip: 'UAOffice',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      headerTooltip: 'Start Date',
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      headerTooltip: 'End Date',
    },
    {
      headerName: 'Status',
      field: 'status',
      headerTooltip: 'Status',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    ...agGrid.auditFields(gridState.isRowEditing),
    {
      ...agGrid.actionColumn({
        cellRendererParams: {
          isActionMenu: true,
          onAction: (action: GRID_ACTIONS) => {
            if ([ GRID_ACTIONS.EDIT, GRID_ACTIONS.DETAILS ].includes(action)) {
              if (searchHeaderRef.current) {
                SearchStore.saveSearchData(location.pathname, {
                  ...searchHeaderRef.current.getFilters(),
                  pagination: gridState.pagination,
                });
              }
            }
          },
          actionMenus: () => [
            {
              title: 'Edit',
              isHidden: !securityModule.isEditable,
              action: GRID_ACTIONS.EDIT,
              to: node => `${node.data?.id}/${VIEW_MODE.EDIT.toLocaleLowerCase()}`,
            },
            {
              title: 'Details',
              action: GRID_ACTIONS.DETAILS,
              to: node => `${node.data?.id}/${VIEW_MODE.DETAILS.toLocaleLowerCase()}`,
            },
          ],
        },
      }),
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: {},
      columnDefs,
    });

    return {
      ...baseOptions,
      pagination: false,
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      isExternalFilterPresent: () => false,
      onFilterChanged: () => loadInitialData({ pageNumber: 1 }),
      onSortChanged: e => {
        agGrid.filtersApi.onSortChanged(e);
        loadInitialData({ pageNumber: 1 });
      },
    };
  };

  const rightContent = (): ReactNode => {
    if (!securityModule.isEditable) {
      return null;
    }

    return <CustomLinkButton variant="contained" startIcon={<AddIcon />} to="new" title="Add Bulletin" />;
  };

  return (
    <>
      <SearchHeaderV2
        ref={searchHeaderRef}
        selectInputs={[
          agGridUtilities.createSelectOption(BULLETIN_FILTERS, BULLETIN_FILTERS.BULLETIN_LEVEL, 'defaultOption'),
        ]}
        rightContent={rightContent}
        onFilterChange={isInitEvent =>
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 })
        }
        onExpandCollapse={agGrid.autoSizeColumns}
      />
      <CustomAgGridReact
        isRowEditing={gridState.isRowEditing}
        rowData={gridState.data}
        gridOptions={gridOptions()}
        serverPagination={true}
        paginationData={gridState.pagination}
        onPaginationChange={loadInitialData}
      />
    </>
  );
});

export default inject('bulletinStore')(Bulletins);
