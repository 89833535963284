import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(0.5),
    },
    active: {
      background: '#E8F2DD',
      color: '#65A61B',
      padding: '3px 20px',
      height: 31,
      fontWeight: 600,
      width: 140,
    },
    staged: {
      color: '#1976D2',
      background: 'rgba(25, 118, 210, 0.2)',
    },
    deprovisioned: {
      backgroundColor: 'rgba(95, 95, 95, 0.2)',
      color: '#5F5F5F',
    },
    passwordExpired: {
      color: '#E11D37',
      background: '#F3D5D9',
    },
    recovery: {
      color: '#D16124',
      background: 'rgba(209, 97, 36, 0.2)',
    },
    provisioned: {
      color: '#1976D2',
      background: 'rgba(25, 118, 210, 0.2)',
    },
    lockedOut: {
      color: '#E11D37',
      background: '#F3D5D9',
    },
    suspended: {
      backgroundColor: 'rgba(95, 95, 95, 0.2)',
      color: '#5F5F5F',
    },
    inactive: {
      backgroundColor: 'rgba(95, 95, 95, 0.2)',
      color: '#5F5F5F',
    },
  });
