import {
  GridOptions,
  GridReadyEvent,
  RowEditingStartedEvent,
  RowNode,
  SuppressKeyboardEventParams,
  TabToNextCellParams,
  ExcelExportParams,
  CsvExportParams,
  RowSelectedEvent,
  RowStyle,
} from 'ag-grid-community';
import { GridPagination, Utilities, IAPIFilterDictionary, rowStyle } from '@wings-shared/core';
import { IGridOptionsBase } from '../Interfaces';
import {
  AgGridActions,
  AgGridGroupHeader,
  AgGridCellEditor,
  AgGridViewRenderer,
  AgGridAutoComplete,
  AgGridDateTimePicker,
  IGridState,
} from '../Components';
import { agGridUtilities } from './AgGridUtilities';
import { actionColumn, auditFields } from './auditFields';
import { useGridFilters } from './UseGridFilters';

export function useAgGrid<Filters, TModel extends { id: number }>(
  apiFilterDictionary: IAPIFilterDictionary<Filters>[],
  gridState: IGridState
) {
  // Another Hooks
  const filtersApi = useGridFilters<Filters>(apiFilterDictionary, gridState);

  // Get Data item by row index
  const _getTableItem = (rowIndex: number): TModel => {
    if (!gridState.gridApi) {
      return null;
    }
    return gridState.gridApi.getDisplayedRowAtIndex(rowIndex)?.data;
  };

  const _startEditingCell = (rowIndex: number, colKey: string): void => {
    if (!gridState.gridApi) {
      return;
    }
    gridState.gridApi.ensureColumnVisible(colKey);
    gridState.gridApi?.startEditingCell({ rowIndex, colKey });
    // gridState.setIsRowEditing(true);
  };

  const _updateTableItem = (rowIndex: number, item: TModel): void => {
    if (!gridState.gridApi) {
      return;
    }

    const rowNode: RowNode = gridState.gridApi.getDisplayedRowAtIndex(rowIndex);
    rowNode?.setData(item);
    gridState.setGridData(
      Utilities.updateArray<TModel>(gridState.data, item, { replace: true, predicate: t => t.id === item.id })
    );
  };

  // Cancel Row Editing
  const cancelEditing = (rowIndex: number, removeRecord: boolean = true): void => {
    if (!gridState.gridApi) {
      return;
    }

    gridState.gridApi.stopEditing(true);
    const data = _getTableItem(rowIndex);

    if (data?.id == 0 && removeRecord) {
      gridState.gridApi.applyTransaction({ remove: [ data ] });
      updatePagination(gridState.pagination.totalNumberOfRecords - 1);
    }
  };

  const _removeTableItems = (items: TModel[]): void => {
    if (!gridState.gridApi) {
      return;
    }

    gridState.gridApi.applyTransaction({ remove: [ ...items ] });
    gridState.gridApi.redrawRows();
    updatePagination(gridState.pagination.totalNumberOfRecords - 1);
  };

  // update grid pagination on add/remove operations
  const updatePagination = (totalNumberOfRecords: number): void => {
    gridState.setPagination(
      new GridPagination({
        ...gridState.pagination,
        totalNumberOfRecords: totalNumberOfRecords === -1 ? 0 : totalNumberOfRecords,
      })
    );
  };

  // Sometimes needs to override ready event in parent component
  const onGridReady = (param: GridReadyEvent): void => {
    gridState.setGridApi(param.api);
    gridState.setColumnApi(param.columnApi);
    gridState.setInitialColDefs(param.api.getColumnDefs());
  };

  // Callback To perform action when row editing started
  const onRowEditingStarted = (event: RowEditingStartedEvent): void => {
    if (gridState.isProcessing) {
      gridState.gridApi.stopEditing();
      return;
    }
    gridState.setIsRowEditing(true);
    gridState.setHasError(true);
    startEditingRow(event);
    filtersApi.suppressFilters(true);
    setColumnVisible('auditDetails', false);
  };

  // Callback To perform action when row editing stopped
  const onRowEditingStopped = (): void => {
    setColumnVisible('auditDetails', true);
    filtersApi.suppressFilters(false);
    gridState.setIsRowEditing(false);
    gridState.gridApi.redrawRows();
    gridState.setHasError(false);
  };

  const startEditingRow = (event: RowEditingStartedEvent): void => {
    if (!event.api) {
      return;
    }

    gridState.setIsRowEditing(true);
    const rowNodes: RowNode[] = Utilities.getRowNodes(event.api).filter(node => node?.rowIndex !== event.rowIndex);
    if (rowNodes.length) {
      event.api.redrawRows({ rowNodes });
    }
  };

  const _getAllTableRows = (): TModel[] => {
    if (!gridState.gridApi) {
      return null;
    }

    gridState.gridApi.selectAll();
    const rowNode: TModel[] = gridState.gridApi.getSelectedRows();
    gridState.gridApi.deselectAll();
    return rowNode;
  };

  const addNewItems = (items: any[], opt?: { startEditing: boolean; colKey: string }): void => {
    if (!gridState.gridApi) {
      return;
    }
    // Scroll to top before start editing 48260
    const pageSize = gridState.gridApi.paginationGetPageSize();
    const currentPage = gridState.gridApi.paginationGetCurrentPage();
    // Get first index of current page
    const addIndex = currentPage * pageSize;
    gridState.gridApi.ensureIndexVisible(addIndex);

    gridState.gridApi.applyTransaction({ add: [ ...items ], addIndex });
    gridState.gridApi.redrawRows();

    if (opt && !opt.startEditing) {
      gridState.gridApi.startEditingCell({ rowIndex: addIndex, colKey: opt.colKey });
    }
    updatePagination(gridState.pagination?.totalNumberOfRecords + 1);
  };

  const setColumnVisible = (columnKey: string, isVisible: boolean): void => {
    if (!gridState.columnApi) {
      return;
    }
    gridState.columnApi.setColumnVisible(columnKey, isVisible);
  };

  // Auto Adjust Grid Headers
  const autoSizeColumns = (): void => {
    if (!gridState.gridApi || !gridState.columnApi) {
      return;
    }

    gridState.toggleAutoSizeColumns ? gridState.gridApi.sizeColumnsToFit() : gridState.columnApi.autoSizeAllColumns();
    gridState.setToggleAutoSizeColumns(!gridState.toggleAutoSizeColumns);
  };

  // When Any Row Item is Selected
  const onRowSelected = (event: RowSelectedEvent) => {
    const rows = event.api.getSelectedRows().length;
    const isAllRowsSelected = event.api.getSelectedRows().length >= event.api.paginationGetPageSize();
    gridState.setHasSelectedRows(Boolean(rows));
    gridState.setIsAllRowsSelected(isAllRowsSelected);
  };

  /* istanbul ignore next */
  // Called From Col Def Of the AgGrid Components
  const getAPIFilterParams = (colId: string, textLength: number = 3, searchType: string = 'contains') => ({
    filterOptions: [ searchType ],
    trimInput: true,
    debounceMs: 500,
    suppressAndOrCondition: true,
    textCustomComparator: () => true,
    // Used to Save Filters when any value changed
    filterModifiedCallback: () => {
      const filterAPI = gridState.gridApi.getFilterApiForColDef(colId);
      const searchValue: string = filterAPI.eValue1.eInput.value || '';
      // Clear Search Filter if Applied earlier
      if (!searchValue) {
        gridState.removeColumnFilter(colId);
        gridState.gridApi.onFilterChanged();
        return;
      }

      // Do not apply filter until text length matched
      if (searchValue && searchValue.length < textLength) {
        gridState.removeColumnFilter(colId);
        filterAPI.setModel(null);
        return;
      }

      // Apply Search Filter
      if (
        !Boolean(searchValue) ||
        (Utilities.isEqual(searchType, 'start') && searchValue.length >= 1) ||
        (Utilities.isEqual(searchType, 'contains') && searchValue.length >= textLength)
      ) {
        gridState.setColumnFilter(colId, { searchType, searchValue });
        gridState.gridApi.onFilterChanged();
      }
    },
  });

  // Grid Base Options
  const gridOptionsBase = (opt: IGridOptionsBase): Partial<GridOptions> => {
    const { isEditable, gridActionProps, editType } = opt;
    return {
      columnDefs: opt.columnDefs,
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: false,
        editable: isEditable || false,
        cellEditor: isEditable ? 'customCellEditor' : null,
        resizable: true,
        cellEditorParams: gridActionProps ? { ...gridActionProps, isEditable, isRowEditing: true } : null,
        cellRendererParams: gridActionProps ? { ...gridActionProps, isEditable, isRowEditing: false } : null,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.editing && !Utilities.hasPressedEnter(params.event)) {
            return true;
          }
          return !params.editing || Utilities.hasPressedEnter(params.event);
        },
        lockPosition: true,
      },
      groupHeaderHeight: 0,
      suppressColumnVirtualisation: true,
      context: { componentParent: opt.context },
      editType: isEditable ? editType || 'fullRow' : null,
      pagination: true,
      paginationPageSize: 30,
      rowHeight: 50,
      stopEditingWhenGridLosesFocus: false,
      suppressClickEdit: !isEditable,
      postSort: filtersApi.postSort,
      onFilterChanged: filtersApi.onFilterChanged,
      onSortChanged: filtersApi.onSortChanged,
      onRowSelected,
      onGridReady,
      onRowEditingStarted,
      onRowEditingStopped,
      getRowStyle: () => rowStyle(gridState.isRowEditing, isEditable) as RowStyle,
      tabToNextCell: ({ previousCellPosition, nextCellPosition }: TabToNextCellParams) => {
        if (!gridState.isRowEditing) {
          return nextCellPosition;
        }
        return nextCellPosition.rowIndex === previousCellPosition.rowIndex ? nextCellPosition : previousCellPosition;
      },
      frameworkComponents: {
        customCellEditor: AgGridCellEditor,
        actionRenderer: AgGridActions,
        customHeader: AgGridGroupHeader,
        viewRenderer: AgGridViewRenderer,
        customAutoComplete: AgGridAutoComplete,
        customTimeEditor: AgGridDateTimePicker,
      },
      defaultCsvExportParams: agGridUtilities.getGridExportParams() as CsvExportParams,
      defaultExcelExportParams: agGridUtilities.getGridExportParams() as ExcelExportParams,
      processCellForClipboard: agGridUtilities.processCellForClipboard,
    };
  };

  const fetchCellInstance = (columnName: string) => {
    return gridState.gridApi.getCellEditorInstances({ columns: [ `${columnName}` ] })[0].getFrameworkComponentInstance();
  };

  return {
    auditFields,
    actionColumn,
    filtersApi,
    autoSizeColumns,
    onRowSelected,
    addNewItems,
    gridOptionsBase,
    _startEditingCell,
    _updateTableItem,
    cancelEditing,
    _removeTableItems,
    _getAllTableRows,
    _getTableItem,
    // Filters
    getAPIFilterParams,
    onRowEditingStarted,
    onRowEditingStopped,
    // refresh column sate
    reloadColumnState: () => gridState.columnApi?.setColumnState(gridState.columnApi?.getColumnState()),
    refreshSelectionState: () => {
      gridState.setHasSelectedRows(false);
      gridState.setIsAllRowsSelected(false);
    },
    fetchCellInstance,
    setColumnVisible,
  };
}
